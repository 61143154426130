import React from 'react'
import {Stack, Box, Container, Grid} from '@sanity/ui'
import {PageProps} from '../../types/app'
import {DefaultLayout} from '@/ui/app'
import {OrgProjectList, OverviewModule, ActivityOverview, HelpModule} from '@/ui/index'
import {useCurrentScopeContext} from '@/context/index'
import {filterOrgs} from '@/data/organizations/useOrganizations'

export default function ManageRoot(props: PageProps) {
  const scope = useCurrentScopeContext()
  const orgs = scope?.orgs || []

  // Separate the personal orgs from all other orgs
  const personalOrg = orgs.find((o) => o.id === 'personal')
  // Create a list of all other orgs
  const otherOrgs = [] || orgs.filter((o) => o.id !== 'personal')
  // Determine if the user has projects in the personal org or any other orgs
  const noProjectsOrOrgs = otherOrgs.length > 0 || (personalOrg?.projects || []).length > 0

  const showActivityModule =
    noProjectsOrOrgs &&
    orgs.filter((org) => org.projects.filter((project) => project.activityFeedEnabled)).length > 0

  return (
    <>
      <DefaultLayout {...props}>
        <Container width={3}>
          <Box paddingTop={1} paddingX={[2, 4]}>
            <Grid columns={[1, 1, 1, 2]} gap={6} autoCols="auto">
              <Box>
                <Stack space={6}>
                  {filterOrgs(orgs).map((org) => (
                    <OrgProjectList key={`project-orgs-${org.id}`} org={org} />
                  ))}
                </Stack>
              </Box>
              <Box>
                <Stack space={6}>
                  {showActivityModule && (
                    <OverviewModule
                      title="Activity"
                      buttonText="View more"
                      path={`manage/activity`}
                    >
                      <ActivityOverview path={`manage/activity`} />
                    </OverviewModule>
                  )}

                  <HelpModule />
                </Stack>
              </Box>
            </Grid>
          </Box>
        </Container>
      </DefaultLayout>
    </>
  )
}
